import {combineReducers, configureStore,} from '@reduxjs/toolkit'
import {setupListeners} from "@reduxjs/toolkit/query";
import {owlracleApi} from "./owlracle/owlracle.api";
import {generalTransferInfoSlice} from "./common/generalTransferInfo.slice";

const rootReducer = combineReducers({
  [owlracleApi.reducerPath]: owlracleApi.reducer,
  [generalTransferInfoSlice.reducerPath]: generalTransferInfoSlice.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(owlracleApi.middleware),
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch


setupListeners(store.dispatch)

import {routePath} from "../../constants/routes";
import {Link} from "react-router-dom";
import {useGetGasFeeEstimationQuery} from "../../store/owlracle/owlracle.api";
import {LoadingCards} from "../../components";
import {cardsGasFeeEstimation} from "../../constants/ui";
import {Tooltip} from "react-tooltip";

export function GasTrackerPage() {
  const {data: ethData, isLoading: isLoadingEthData} = useGetGasFeeEstimationQuery('eth')
  const {data: bscData, isLoading: isLoadingBscData} = useGetGasFeeEstimationQuery('bsc')

  return (
    <section className="section-gas-tracker">
      <div className="grid gap-5">
        <h1 className="h1-title uppercase">Ethereum Gas Tracker ⛽</h1>

        <div className="gas-tracker-cards">
          {
            isLoadingEthData ? (
              <LoadingCards cards={cardsGasFeeEstimation}/>
            ) : ethData?.speeds.map((speed, index) => {
              return (
                <Link key={cardsGasFeeEstimation[index].name} to={routePath.eth}
                      className={`gas-tracker-card ${cardsGasFeeEstimation[index].name}`}>
                  <h3
                    className="h3-title font-medium capitalize">{cardsGasFeeEstimation[index].name} {cardsGasFeeEstimation[index].icon}
                    <a className="ml-2.5 cursor-pointer" id={'tooltipEth-' + cardsGasFeeEstimation[index].name}><i className="icon-info"/></a>
                    <Tooltip anchorSelect={'#tooltipEth-' + cardsGasFeeEstimation[index].name} content={cardsGasFeeEstimation[index].tooltip}/>
                  </h3>
                  <div className="amount-gwei">{speed.maxFeePerGas?.toFixed(3) || 0} GWEI</div>
                  <div className="h4-title font-medium">{+speed.estimatedFee?.toFixed(4)} $</div>
                  <div>
                    <div><span className="text-gray-700">Base:</span> {speed.baseFee?.toFixed(2)} GWei</div>
                    <div><span className="text-gray-700">Tip:</span> {speed.maxPriorityFeePerGas?.toFixed(2)} GWei</div>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>

      <div className="grid gap-5">
        <h1 className="h1-title uppercase">BNB Smart Chain Gas Tracker ⛽</h1>

        <div className="gas-tracker-cards">
          {
            isLoadingBscData ? (
              <LoadingCards cards={cardsGasFeeEstimation}/>
            ) : bscData?.speeds.map((speed, index) => {
              return (
                <Link key={cardsGasFeeEstimation[index].name} to={routePath.bsc}
                      className={`gas-tracker-card ${cardsGasFeeEstimation[index].name}`}>
                  <h3
                    className="h3-title font-medium capitalize">{cardsGasFeeEstimation[index].name} {cardsGasFeeEstimation[index].icon}
                    <a className="ml-2.5 cursor-pointer" id={'tooltipBsc-' + cardsGasFeeEstimation[index].name}><i className="icon-info"/></a>
                    <Tooltip anchorSelect={'#tooltipBsc-' + cardsGasFeeEstimation[index].name} content={cardsGasFeeEstimation[index].tooltip}/>
                  </h3>
                  <div className="amount-gwei">{speed.gasPrice?.toFixed(3) || 0} GWEI</div>
                  <div className="h4-title font-medium">{+speed.estimatedFee?.toFixed(4)} $</div>
                </Link>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}
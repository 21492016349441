import {IWeb3Facade} from "../IWeb3Facade";
import {fromWei, HexStr, toWei} from "../../../../store/web3/web3";
import {
  IAccount,
  IBalanceData,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IMapValueByAddress,
  ITransactionPriorityEnum
} from "../../types";
import {ETHFacade, InitDataType, ITxEthData} from "../ETH_Network/ETHFacade";
import {BASETokens} from "../../../../store/basescan/BASETokens";
import {createPublicClient, http} from "viem";
import {base} from "viem/chains";
import {publicActionsL2} from "viem/op-stack";
import {GasHelper} from "../../../../helpers";
import {BASETestnetTokens} from "../../../../store/basescan/BASETestnetTokens";


interface IDataForGenerateBSCTransactions extends IDataForGenerateTransactions {
  balanceDataByAddress: IBalanceData,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

interface IDataForSendBSCTransactions extends IDataForSendTransactions {
  balanceDataByAddress: IBalanceData,
  privateKeyByAddress: IMapValueByAddress<IAccount['privateKey']>,
  transactionDataByAddress: IMapValueByAddress<ITxEthData>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

export const BaseTransactionPriorityEnum: ITransactionPriorityEnum = {
  slow: "slow",
  average: "average",
  fast: "fast"
} as const

export const BaseInitData: InitDataType = {
  defaultTransactionPriority: BaseTransactionPriorityEnum.average,
  transactionPriorityOptions: {
    [BaseTransactionPriorityEnum.slow]: "Slow",
    [BaseTransactionPriorityEnum.average]: "Average",
    [BaseTransactionPriorityEnum.fast]: "Fast",
  },
  fetchGasPriceConf: {
    apikey: 'not_using',
    url: process.env.REACT_APP_LINK_FOR_BASE_GAS_PRICE_API
  },
  web3HttpProviderLink: process.env.REACT_APP_BASE_WEB3_HTTP_PROVIDER,
  tokensDict: process.env.REACT_APP_ENVIRONMENT === 'dev' ? BASETestnetTokens : BASETokens,
  network: 'base',
  linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_BASE_SCAN
}

class BASEFacade extends ETHFacade implements IWeb3Facade {

  constructor(initData?: InitDataType) {
    super(initData || BaseInitData)
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(this._fetchGasPriceConf.url, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      average_block_time: number;
      coin_image: null | string;
      coin_price: string;
      coin_price_change_percentage: number;
      gas_price_updated_at: Date;
      gas_prices: {
        average: number;
        fast: number;
        slow: number;
      };
      gas_prices_update_in: number;
      gas_used_today: string;
      market_cap: string;
      network_utilization_percentage: number;
      secondary_coin_price: number | string;
      static_gas_price: null;
      total_addresses: string;
      total_blocks: string;
      total_gas_used: string;
      total_transactions: string;
      transactions_today: string;
      tvl: null;
    };
    /**
     * In test(dev) env use  web3.eth.getGasPrice() to get actual price for testnet
     */
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const {getGasPriceInWei} = this._web3Provider
      const slowInWei: bigint = await getGasPriceInWei()
      const slowInGwei = BigInt(Math.ceil(Number(fromWei(slowInWei, 'gwei'))))
      result.gas_prices = {
        slow: Number(slowInGwei),
        average: Number(GasHelper.gasPricePlusPercent(slowInGwei, 50)),
        fast: Number(GasHelper.gasPricePlusPercent(slowInGwei, 100))
      }
      console.table({...result.gas_prices})
    }

    return BigInt(toWei((result.gas_prices[transactionPriority] || 0), 'Gwei'))
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxEthData>, gasPriceInWei: bigint) {
    const {txDataByAddress, feeDataByAddress} = await super._estimateFee(txDataForEstimateByAddress, gasPriceInWei)
    /**
     * Solution for L2 chain
     */
    const publicClient: any = createPublicClient({
      chain: base,
      transport: http(),
    } as any).extend(publicActionsL2())

    const {done, value} = txDataForEstimateByAddress.values().next()
    if (done) {
      return {txDataByAddress, feeDataByAddress}
    }

    const item = value as ITxEthData
    const feeL1 = await publicClient.estimateL1Fee({
      account: item.from,
      to: item.to,
      value: item.value
    })
    const feeL1More = GasHelper.gasPay(feeL1)
    console.log('base feeL1', feeL1More)

    for (const address of txDataByAddress.keys()) {
      feeDataByAddress.set(address, feeDataByAddress.get(address) + feeL1More)
      const item = txDataByAddress.get(address)
      if (item.value - feeL1More < BigInt(0)) {
        txDataByAddress.delete(address)
      } else {
        item.value = item.value - feeL1More
        txDataByAddress.set(address, item)
      }

    }
    return {txDataByAddress, feeDataByAddress}
  }
}

export {BASEFacade}
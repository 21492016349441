import {ITransactionPriorityEnum} from "../../ConsolidationTool/types";
import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {fromWei, toWei} from "../../../store/web3/web3";
import {IGasPrice} from "../../../models/chainScan.models";
import {ETH_DisperseFacade} from "./ETH_DisperseFacade";
import {GasHelper} from "../../../helpers";

function adapterGasPrice(rawResult: {
  average: number,
  fast: number,
  slow: number,
}): IGasPrice {
  return {
    low: rawResult.slow,
    medium: rawResult.average,
    high: rawResult.fast
  }
}

class BASE_DisperseFacade extends ETH_DisperseFacade implements IWeb3DisperseFacade {
  constructor() {
    super({
      web3HttpProviderLink: process.env.REACT_APP_BASE_WEB3_HTTP_PROVIDER,
      network: "base",
      linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_BASE_SCAN,
      defaultTransactionPriority: "high",
      fetchGasPriceConf: {
        apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_BASE_SCAN_API,
        url: process.env.REACT_APP_LINK_FOR_BASE_GAS_PRICE_API
      }
    })
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(this._fetchGasPriceConf.url, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      average_block_time: number;
      coin_image: null | string;
      coin_price: string;
      coin_price_change_percentage: number;
      gas_price_updated_at: Date;
      gas_prices: {
        average: number;
        fast: number;
        slow: number;
      };
      gas_prices_update_in: number;
      gas_used_today: string;
      market_cap: string;
      network_utilization_percentage: number;
      secondary_coin_price: number | string;
      static_gas_price: null;
      total_addresses: string;
      total_blocks: string;
      total_gas_used: string;
      total_transactions: string;
      transactions_today: string;
      tvl: null;
    };
    /**
     * In test(dev) env use  web3.eth.getGasPrice() to get actual price for testnet
     */
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const {getGasPriceInWei} = this._web3Provider
      const slowInWei: bigint = await getGasPriceInWei()
      const slowInGwei = BigInt(Math.ceil(Number(fromWei(slowInWei, 'gwei'))))
      result.gas_prices = {
        slow: Number(slowInGwei),
        average: Number(GasHelper.gasPricePlusPercent(slowInGwei, 50)),
        fast: Number(GasHelper.gasPricePlusPercent(slowInGwei, 100))
      }
      console.table({...result.gas_prices})
    }
    const gasPrices = adapterGasPrice(result.gas_prices)
    return BigInt(toWei((gasPrices[transactionPriority] || 0), 'Gwei'))
  }
}

export {BASE_DisperseFacade}
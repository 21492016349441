import React, {TextareaHTMLAttributes, ReactNode, useMemo, forwardRef} from "react";

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  isWarning?: boolean
  feedback?: string | ReactNode | null
  isInvalid?: boolean
  isValid?: boolean
  statusClass?: 'warning' | 'valid' | 'invalid',
  inputRef?: React.RefObject<HTMLTextAreaElement>
}

const feedbackBlock = (
  feedback: TextareaProps['feedback'],
  statusClass: TextareaProps['statusClass']
) => {
  if (!feedback) return null

  return (
    <div className={statusClass + '-feedback feedback'}>{feedback}</div>
  )
}

export const Textarea = forwardRef(function TextArea(props: TextareaProps, ref: React.ForwardedRef<HTMLTextAreaElement>) {
  const {
    isWarning,
    feedback,
    isInvalid,
    isValid,
    ...rest
  } = props

  const statusClass = useMemo(() => {
    if (isWarning) {
      return 'warning'
    }

    if (isInvalid) {
      return 'invalid'
    }

    if (isValid) {
      return 'valid'
    }

    return undefined
  }, [isWarning, isInvalid, isValid])

  return (
    <div className={`input-block ${statusClass}`}>
				<textarea
          cols={props?.cols ?? 30}
          rows={props?.rows ?? 2}
          className="textarea-custom"
          ref={ref}
          {...rest}
        />

      {feedbackBlock(feedback, statusClass)}
    </div>
  )
})
import {PropsWithChildren} from "react";

export type BadgeType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'neutral'

export interface IBadgeProps {
  type: BadgeType
}

const classesByTypes: Record<BadgeType, string> = {
  primary: 'bg-sky-500 text-white',
  secondary: 'bg-neutral-400 text-white',
  success: 'bg-green-500 text-white',
  danger: 'bg-red-600 text-white',
  warning: 'bg-orange-700 text-stone-950',
  info: 'bg-cyan-400 text-stone-950',
  neutral: 'bg-slate-50 text-stone-950',
}

export const Badge = ({
                        children,
                        type
                      }: PropsWithChildren<IBadgeProps>) => {

  return (
    <div
      className={`inline-block whitespace-nowrap rounded-[0.27rem] px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none ${classesByTypes[type]}`}>
      {children}
    </div>
  )
}
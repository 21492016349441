import {memo} from "react";

interface AlertBlockProps {
	title?: React.ReactNode | string
	children?: React.ReactNode | JSX.Element | null
	classNameBlock?: string
	bgColorBlock?: string
}

export const AlertBlock = memo(({
	title,
	children,
	classNameBlock = '',
	bgColorBlock = 'bg-[#171717]/80',
}: AlertBlockProps) => {
	return (
		<div
			className={`${bgColorBlock} pt-7 pb-10 px-10 rounded-2xl ${classNameBlock}`}
		>
			{title ? (
				<div className={`flex items-center mb-5`}>
					{typeof title === 'string' ? (
						<div className="h4-title">{title}</div>
					) : (
						title
					)}
				</div>
			) : null}

			{children}
		</div>
	)
})

import { StylesConfig } from 'react-select'

export const customStyles: StylesConfig = {
	control: (styles, { isDisabled }) => ({
		...styles,
		backgroundColor: isDisabled ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
		borderColor: 'rgba(255, 255, 255, 0.6)',
		borderRadius: '5px',
		boxShadow: 'none',
		minHeight: '48px',
		cursor: isDisabled ? 'not-allowed' : 'pointer',

		':hover': {
			...styles[':hover'],
			borderColor: 'rgba(255, 255, 255, 0.8)',
		},
	}),
	input: (styles, { isDisabled }) => ({
		...styles,
		color: 'white',
		fontWeight: 500,
		fontSize: '16px',
		cursor: isDisabled ? 'not-allowed' : 'pointer',
	}),
	placeholder: (styles) => ({
		...styles,
		color: '#A8B0C1',
		fontSize: '16px',
	}),
	menu: (styles) =>
		Object.assign(styles, {
			zIndex: 1000,
			background: 'rgba(23, 23, 26, 0.8)',
			backdropFilter: 'blur(15px)',
			borderRadius: 15,
			padding: '20px 0',
		}),
	menuList: (styles) =>
		Object.assign(styles, {
			padding: '0 20px',
		}),
	option: (provided, state) => ({
		...provided,
		position: 'relative',
		backgroundColor: 'none',
		borderRadius: '15px',
		border: 'none',
		color: '#FFF',
		padding: '20px 44px 20px 20px',
		fontWeight: 500,
		cursor: !state.isSelected && state.isFocused ? 'pointer' : 'not-allowed',
		transition: 'background-color .3s ease-in-out',

		':after': {
			content: state.isSelected
				? "url(\"data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_12345_2)'%3E%3Cpath d='M17 3.33989C18.5083 4.21075 19.7629 5.46042 20.6398 6.96519C21.5167 8.46997 21.9854 10.1777 21.9994 11.9192C22.0135 13.6608 21.5725 15.3758 20.72 16.8946C19.8676 18.4133 18.6332 19.6831 17.1392 20.5782C15.6452 21.4733 13.9434 21.9627 12.2021 21.998C10.4608 22.0332 8.74055 21.6131 7.21156 20.7791C5.68256 19.9452 4.39787 18.7264 3.48467 17.2434C2.57146 15.7604 2.06141 14.0646 2.005 12.3239L2 11.9999L2.005 11.6759C2.061 9.94888 2.56355 8.26585 3.46364 6.79089C4.36373 5.31592 5.63065 4.09934 7.14089 3.25978C8.65113 2.42021 10.3531 1.98629 12.081 2.00033C13.8089 2.01437 15.5036 2.47589 17 3.33989ZM15.707 9.29289C15.5348 9.12072 15.3057 9.01729 15.0627 9.002C14.8197 8.98672 14.5794 9.06064 14.387 9.20989L14.293 9.29289L11 12.5849L9.707 11.2929L9.613 11.2099C9.42059 11.0607 9.18037 10.9869 8.93741 11.0022C8.69444 11.0176 8.46541 11.121 8.29326 11.2932C8.12112 11.4653 8.01768 11.6943 8.00235 11.9373C7.98703 12.1803 8.06086 12.4205 8.21 12.6129L8.293 12.7069L10.293 14.7069L10.387 14.7899C10.5624 14.926 10.778 14.9998 11 14.9998C11.222 14.9998 11.4376 14.926 11.613 14.7899L11.707 14.7069L15.707 10.7069L15.79 10.6129C15.9393 10.4205 16.0132 10.1802 15.9979 9.93721C15.9826 9.69419 15.8792 9.46509 15.707 9.29289Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_12345_2'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A\")"
				: "url(\"data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_10406_5194)'%3E%3Cpath d='M9 6L15 12L9 18' stroke='%23A8B0C1' stroke-width='0.916667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_10406_5194'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A\")",
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			right: 20,
			width: 24,
			height: 24,
			fontSize: '24px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},

		':hover': {
			backgroundColor: state.isSelected ? 'none' : 'rgba(255, 255, 255, 0.05)',
		},
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		transition: 'color, transform 150ms',
		padding: '2px 20px',

		'.fa-caret-down': {
			transition: 'all .3s ease-in-out',
			transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'none',
		},
	}),
	singleValue: (provided, state) => {
		const transition = 'opacity 300ms'

		return {
			...provided,
			transition,
			color: 'white',
			fontWeight: 500,
			fontSize: '16px',
		}
	},
	valueContainer: (styles, state) => {
		return {
			...styles,
			padding: '2px 0 2px 20px',
		}
	},
}

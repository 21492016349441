import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import baseLogo from '../../assets/images/base-dark.svg'

const BASE_DEFAULT_IMG = baseLogo as string

const BASETestnetTokens: ITokenDict = {
  [NetworkCurrencyEnum.eth]: {
    "title": "Ether",
    "img": "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    "rate": "$3580.00",
    "address": undefined,
    "link": "https://basescan.org/",
    "group": `Base Currency (${NetworkCurrencyEnum.eth})`,
    "website": "https://www.base.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.eth
  },
  USDT: {
    "title": "Tether USD",
    "img": "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    "rate": "$1.00",
    "address": "0x22c0DB4CC9B339E34956A5699E5E95dC0E00c800",
    "link": "https://sepolia.basescan.org/token/0x22c0db4cc9b339e34956a5699e5e95dc0e00c800",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 18,
    "symbol": "USDT"
  },
}

export {BASETestnetTokens, BASE_DEFAULT_IMG}
import {Block, Select} from "../../../components";

export interface IGasLimitBlockProps {
  optionsPriority: any,
  currentPriority: string,

  onChangePriority(currentPriority: string): void
}

export const TransactionPriorityBlock = ({
                                           optionsPriority,
                                           currentPriority,
                                           onChangePriority,
                                         }: IGasLimitBlockProps) => {

  return (
    <Block className="h-max">
      <div>
        <label className="label-primary">Select priority</label>
        <Select
          name="priority"
          value={{
            label: optionsPriority[currentPriority],
            value: currentPriority
          }}
          onChange={(newValue: any) => onChangePriority(newValue?.value as string)}
          options={Object.keys(optionsPriority).map(key => ({
            label: optionsPriority[key],
            value: key
          }))}
        />
      </div>
    </Block>
  )
}
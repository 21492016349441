import {components, DropdownIndicatorProps} from 'react-select'
const DropdownIndicator: React.FC<DropdownIndicatorProps> = (
  indicatorProps
) => {
  return (
    <components.DropdownIndicator {...indicatorProps}>
      <i className="fas fa-caret-down text-white/50 font-bold font-12"/>
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;

import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import baseLogo from '../../assets/images/base-dark.svg'

const BASE_DEFAULT_IMG = baseLogo as string

const BASETokens: ITokenDict = {
  [NetworkCurrencyEnum.eth]: {
    "title": "Ether",
    "img": "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    "rate": "$3580.00",
    "address": undefined,
    "link": "https://basescan.org/",
    "group": `Base Currency (${NetworkCurrencyEnum.eth})`,
    "website": "https://www.base.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.eth
  },
  USDC: {
    "title": "Token USDC",
    "img": "https://basescan.org/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    "link": "https://basescan.org/token/0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
    "group": `Tokens (BEP 20)`,
    "website": "https://www.circle.com/en/usdc",
    "desc": "",
    "decimal": 6,
    "symbol": "USDC"
  },
}

export {BASETokens, BASE_DEFAULT_IMG}
import {Button} from "../Button";
import {ButtonSize} from "../../../constants/ui";
import {InputHTMLAttributes, ReactNode, useMemo, useState} from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement>{
  showIcon?: boolean
  togglePasswordRender?: boolean
  isWarning?: boolean
  feedback?: string | ReactNode | null
  isInvalid?: boolean
  isValid?: boolean
  append?: any
  statusClass?: 'warning' | 'valid' | 'invalid'
}

const feedbackBlock = (
  feedback: InputProps['feedback'],
  statusClass: InputProps['statusClass']
) => {
  if (!feedback) return null

  return (
    <div className={statusClass + '-feedback feedback'}>{feedback}</div>
  )
}

export const Input = (props: InputProps) => {
  const {
    isWarning,
    feedback,
    isInvalid,
    isValid,
    append,
    type = 'text',
    showIcon = false,
    togglePasswordRender = false,
    ...rest
  } = props

  const [inputType, setInputType] = useState(type)

  const statusClass = useMemo(() => {
    if (isWarning) {
      return 'warning'
    }

    if (isInvalid) {
      return 'invalid'
    }

    if (isValid) {
      return 'valid'
    }

    return undefined
  }, [isWarning, isInvalid, isValid])

  const handleShowPass = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setInputType(inputType === 'password' ? 'input' : 'password')
  }

  const preventSymbols = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const exceptThisSymbols = ['e', 'E', '+', '-']
    type === 'number' && exceptThisSymbols.includes(e.key) && e.preventDefault()
  }

  const onWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur()
  }

  return (
    <div
      className={`input-block ${statusClass ?? ''} ${
        showIcon ? 'input-with-icon' : ''
      }`}
    >
      {(append || togglePasswordRender) && !showIcon ? (
        <div
          className={`flex items-center gap-1 input-append ${
            props.disabled ? 'disabled' : ''
          }`}
        >
          <input
            onKeyPress={preventSymbols}
            onWheel={onWheel}
            className="input"
            type={inputType}
            {...rest}
          />

          {togglePasswordRender ? (
            <Button
              type="button"
              aria-label={inputType !== 'password' ? 'Hide password' : 'Show password'}
              className="-mr-5 max-w-max min-w-fit hover:opacity-80 transition-opacity"
              size={ButtonSize.M}
              onClick={handleShowPass}
            >
                {inputType !== 'password' ? (
                  <i
                    key="icon-eye"
                    className="icon-eye font-10"
                  />
                ) : (
                  <i
                    key="icon-eye-slash"
                    className="icon-eye-slash font-12"
                  />
                )}
            </Button>
          ) : (
            append
          )}
        </div>
      ) : (
        <input
          onKeyPress={preventSymbols}
          onWheel={onWheel}
          className="input"
          type={inputType}
          {...rest}
        />
      )}
      {feedbackBlock(feedback, statusClass)}
    </div>
  )
}
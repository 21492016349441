import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";

const DOGE_DEFAULT_IMG = 'none' as const

const DOGETokens: ITokenDict = {
    [NetworkCurrencyEnum.doge]: {
      "title": "Doge",
      "img": "https://dive.dogechain.dog/imgs/dogewDOGE.png",
      "rate": "$0.15",
      "address": undefined,
      "link": "https://dogechain.info/",
      "group": `Base Currency (${NetworkCurrencyEnum.doge})`,
      "website": "https://dogechain.info/",
      "desc": "",
      "decimal": 8,
      "symbol": NetworkCurrencyEnum.doge
    },
}

export {DOGETokens, DOGE_DEFAULT_IMG}
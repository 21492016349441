import {PropsWithChildren} from "react";

interface ModalProps {
  show: boolean
  hideCross?: boolean
  handleClose: () => void
  title: string
  size?: 'sm' | 'lg' | 'xl' | ''
}

export const Modal = ({
                        children,
                        show,
                        title,
                        handleClose,
                        size = '',
                        hideCross = false
                      }: PropsWithChildren<ModalProps>) => {
  return (
    <div className={`modal${show ? ' active' : ''}`} onClick={handleClose}>
      <div className={`modal-content${show ? ' active' : ''} ${size}`} onClick={e => e.stopPropagation()}>
        <div className="modal-content__header">
          {title}

          {!hideCross ? (
            <button
              type="button"
              onKeyDown={(e) => {
                if (e.which == 32 || e.which == 13) {
                  e.preventDefault()
                }
              }}
              onClick={handleClose}
              className="absolute right-5 top-5 z-1 focus:outline-none hover:rotate-[360deg] hover:opacity-70 hover:transition-all"
            >
              <i className="icon-cross text-gray-700 font-light text-sm"/>
            </button>
          ) : null}
        </div>
        <div className="modal-content__body">
          {children}
        </div>
      </div>
    </div>
  )
}
import {
  AddressType,
  IAccount,
  IMapValueByAddress,
  ITransactionPriorityEnum,
  NetworkType,
  PrivateKeyType
} from "../../ConsolidationTool/types";
import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {
  fromWei,
  GAS_FOR_SEND_ETHEREUM,
  HexStr,
  isAddress,
  isHexStrict,
  setProviderWeb3,
  toBigInt,
  toHex,
  toWei,
  Web3InitiatedType
} from "../../../store/web3/web3";
import {
  AddressHexStr,
  ApiScanResponse,
  IGasPrice,
  IGasPriceResult,
  IRpcResponse
} from "../../../models/chainScan.models";
import {GasHelper} from "../../../helpers";
import {Web3Account} from "web3-eth-accounts";
import {JsonRpcError} from "web3-types/src/json_rpc_types";

function adapterGasPrice(rawResult: IGasPriceResult): IGasPrice {
  return {
    low: rawResult.SafeGasPrice,
    medium: rawResult.ProposeGasPrice,
    high: rawResult.FastGasPrice
  }
}

export type InitDisperseDataType = {
  network: NetworkType,
  linkForTxScan: string,
  web3HttpProviderLink: string,
  fetchGasPriceConf: { apikey: string; url: string }
  defaultTransactionPriority: keyof ITransactionPriorityEnum,
}

const ETHInitDisperseData: InitDisperseDataType = {
  web3HttpProviderLink: process.env.REACT_APP_ETH_WEB3_HTTP_PROVIDER,
  fetchGasPriceConf: {
    apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_ETH_SCAN_API,
    url: process.env.REACT_APP_LINK_FOR_ETH_GAS_PRICE_API
  },
  network: "eth",
  linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_ETH_SCAN,
  defaultTransactionPriority: "high",
}

class ETH_DisperseFacade implements IWeb3DisperseFacade {
  protected readonly _web3Provider: Web3InitiatedType
  protected readonly _fetchGasPriceConf: { apikey: string; url: string }
  protected readonly _network: NetworkType
  protected readonly _linkForTxScan: string
  protected readonly _defaultTransactionPriority: keyof ITransactionPriorityEnum

  constructor(initData?: InitDisperseDataType) {
    this._web3Provider = setProviderWeb3(initData?.web3HttpProviderLink || ETHInitDisperseData.web3HttpProviderLink)
    this._fetchGasPriceConf = initData?.fetchGasPriceConf || ETHInitDisperseData.fetchGasPriceConf
    this._network = initData?.network || ETHInitDisperseData.network
    this._linkForTxScan = initData?.linkForTxScan || ETHInitDisperseData.linkForTxScan
    this._defaultTransactionPriority = initData?.defaultTransactionPriority || ETHInitDisperseData.defaultTransactionPriority
  }

  get linkForTxScan() {
    return this._linkForTxScan
  }

  get network() {
    return this._network
  }

  getLimitReceiver(): number {
    return 800
  }

  async estimateTransactions(data: {
    amountInUnitByReceiver: IMapValueByAddress<bigint>
  }): Promise<any> {
    const {amountInUnitByReceiver} = data
    const _gasPriceWei = await this._fetchGasPriceInWei(this._defaultTransactionPriority)


    //calculate fee without optimization
    const totalGasFeeInWei: bigint = GasHelper.gasPay(GAS_FOR_SEND_ETHEREUM * amountInUnitByReceiver.size)

    return {
      notOptimizedFeeInUnit: totalGasFeeInWei * _gasPriceWei,
      optimizedFeeInUnit: undefined
    }
  }

  isExceptSender(receiverAddress: AddressHexStr, senderAddress: AddressHexStr): boolean {
    return receiverAddress.toLowerCase() === senderAddress?.toLowerCase()
  }

  async sendTransactions(data: {
    amountInUnitByReceiver: IMapValueByAddress<bigint>
    senderAccount: Web3Account
  }): Promise<IMapValueByAddress> {
    const {amountInUnitByReceiver, senderAccount} = data
    const {
      getTransactionCount, getChainId,
      getNetworkId, BatchRequest, sendSignedTransaction
    } = this._web3Provider

    const resultTxReceipt: IMapValueByAddress<HexStr> = new Map()
    const signedTxByAddress = new BatchRequest()
    const _gasPriceWei = await this._fetchGasPriceInWei(this._defaultTransactionPriority)

    const countTransaction = await getTransactionCount(senderAccount.address)
    let nonce = countTransaction || 0
    const chainId = await getChainId()
    const networkId = await getNetworkId()

    for (const [account, amountInWei] of Array.from(amountInUnitByReceiver)) {
      if (this.isExceptSender(account as AddressHexStr, senderAccount.address as AddressHexStr)) continue

      // Sign transaction
      const {rawTransaction} = await senderAccount.signTransaction({
        gas: toHex(GasHelper.gasPay(GAS_FOR_SEND_ETHEREUM)),
        from: senderAccount.address,
        to: account,
        value: toHex(amountInWei),
        gasPrice: toHex(_gasPriceWei),
        chainId: toHex(chainId),
        networkId: toHex(networkId),
        nonce: toHex(nonce++)
      })
      signedTxByAddress.add(sendSignedTransaction.request(rawTransaction, account as AddressHexStr))
    }

    //Send raw transaction by account
    const dataBatchTx = await signedTxByAddress.execute({timeout: 30000})
    for (let txResult of dataBatchTx) {
      if (txResult.error) {
        const errorData = txResult.error as JsonRpcError
        throw new Error(`${errorData.message} [${errorData.code}]`)
      }
      let itemSuccess = txResult as IRpcResponse
      resultTxReceipt.set(itemSuccess.id, itemSuccess.result as HexStr)
    }
    return resultTxReceipt
  }

  async fetchBalanceInUnit(address: AddressType): Promise<bigint> {
    return this._web3Provider.getBalance(address)
  }

  validateAddress(address: AddressType): boolean {
    return isHexStrict(address) && isAddress(address)
  }

  privateKeyToAccount(privateKey: PrivateKeyType): IAccount {
    if (!isHexStrict(privateKey)) {
      privateKey = `0x${privateKey}`
    }

    return this._web3Provider.privateKeyToAccount(privateKey)
  }

  toUnitFromBaseCurrency(amount: string): bigint {
    return BigInt(toWei(amount, 'ether'))
  }

  toBaseCurrencyFromUnit(amount: bigint): string {
    return fromWei(amount, 'ether')
  }

  protected async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const params = {
      module: 'gastracker',
      action: 'gasoracle',
      apikey: this._fetchGasPriceConf.apikey
    }
    const data: ApiScanResponse<IGasPriceResult> = await fetch(this._fetchGasPriceConf.url + '/api/?' + new URLSearchParams(params)).then(response => response.json())
    if (parseInt(data?.status || '0') === 1) {
      /**
       * In test(dev) env use  web3.eth.getGasPrice() to get actual price for testnet
       */
      if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
        const {getGasPriceInWei} = this._web3Provider
        const slowInWei: bigint = await getGasPriceInWei()
        const slowInGwei = BigInt(Math.ceil(Number(fromWei(slowInWei, 'gwei'))))
        data.result = {
          SafeGasPrice: slowInGwei.toString(),
          ProposeGasPrice: GasHelper.gasPricePlusPercent(slowInGwei, 50).toString(),
          FastGasPrice: GasHelper.gasPricePlusPercent(slowInGwei, 100).toString(),
          LastBlock: "_not_realized_",
          suggestBaseFee: "_not_realized_",
          gasUsedRatio: "_not_realized_",
        }
        console.table({...data.result})
      }
      const gasPrice = adapterGasPrice(data.result)
      return toBigInt(toWei(gasPrice[transactionPriority as keyof IGasPrice] || 0, 'Gwei'))
    }

    return BigInt(0)
  }
}

export {ETH_DisperseFacade}
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AddressType, NetworkType} from "../../pages/ConsolidationTool/types";

export interface GeneralTransferInfoSlice {
  privateKeys: string,
  receiverAddress: AddressType,
  network: NetworkType | "",
}

const initialState: GeneralTransferInfoSlice = {
  privateKeys: "",
  receiverAddress: "",
  network: ""
}

export const generalTransferInfoSlice = createSlice({
  name: "generalTransferInfo",
  initialState,
  reducers: {
    setPrivateKeys: (state, action: PayloadAction<string>) => {
      state.privateKeys = action.payload
    },
    resetPrivateKeys: (state) => {
      state.privateKeys = ""
    },
    setReceiverAddress: (state, action: PayloadAction<string>) => {
      state.receiverAddress = action.payload
    },
    resetReceiverAddress: (state) => {
      state.receiverAddress = ""
    },
    setPrivateKeysNetwork: (state, action: PayloadAction<NetworkType>) => {
      state.network = action.payload
    }
  }
})

export const {reducer: generalTransferInfoReducer, actions: generalTransferInfoActions} = generalTransferInfoSlice
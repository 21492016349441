import {ButtonHTMLAttributes, FC, memo} from 'react'
import {ButtonSize} from "../../../constants/ui";


interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  isDisabledGray?: boolean
  size?: ButtonSize
  icon?: React.ReactNode
}

export const Button: FC<ButtonProps> = memo((props) => {
  const {
    className,
    size = ButtonSize.L,
    isDisabledGray = false,
    icon,
    children,
    ...otherProps
  } = props

  return (
    <button
      {...otherProps}
      className={
        'btn ' +
        (className ? className : '') +
        ' ' +
        size +
        ' ' +
        ((isDisabledGray && otherProps?.disabled)
          ? 'btn-disabled-gray'
          : '')
      }
    >
      {
        icon ? (
          <div className="flex items-center gap-2.5">
            {children} {icon}
          </div>
        ) : children
      }
    </button>
  )
})

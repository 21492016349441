import {useState} from "react";

const body = document.getElementsByTagName('body')[0]

export const useModal = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return {
    isOpenModal,
    closeModal: () => {
      body?.removeAttribute('style')
      setIsOpenModal(false)
    },
    openModal: () => {
      body.style.overflow = 'hidden'
      setIsOpenModal(true)
    }
  }
};

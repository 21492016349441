interface LoaderProps { size?: number, className?: string, text?: React.ReactNode | string }

export const Loader = ({size = 40, text, className = ''}: LoaderProps) => {

  const loaderComponent = () => {
    return (
      <div
        style={{
          height: size,
          width: size
        }} className={`loader ${className}`}
      />
    )
  }


  if (!text) {
    return loaderComponent()
  }

  return (
    <div className={`text-center ${className}`}>
      {loaderComponent()}

      <p className="mt-2.5">{text}</p>
    </div>
  )
}
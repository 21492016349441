import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";

const XRP_DEFAULT_IMG = 'none' as const

const XRPTokens: ITokenDict = {
  [NetworkCurrencyEnum.xrp]: {
    "title": "Ripple",
    "img": "https://s2.coinmarketcap.com/static/img/coins/64x64/52.png",
    "rate": "$0.5476",
    "address": undefined,
    "link": "https://xrpscan.com/",
    "group": `Base Currency (${NetworkCurrencyEnum.xrp})`,
    "website": "https://xrpl.org/",
    "desc": "",
    "decimal": 6,
    "symbol": NetworkCurrencyEnum.xrp
  },
}

export {XRPTokens, XRP_DEFAULT_IMG}
export function fromUnitToToken(precision: number) {
  return (weiAmount: BigInt): string => {
    let result: number = (Number(weiAmount) / (10 ** precision))
    return result.toFixed(getPrecisionByNumber(result))
  }
}

export function getPrecisionByNumber(num: number) {
  if (!isFinite(num)) return 0;
  let e = 1, p = 0;
  while (Math.round(num * e) / e !== num) {
    e *= 10;
    p++;
  }
  return p;
}

import {BscInitData, ITxEthData} from "./BSCFacade";
import {
  IAccount,
  IBalanceTokenData,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IMapValueByAddress,
  ITransactionPriorityEnum
} from "../../types";
import {HexStr} from "../../../../store/web3/web3";
import BEP20TokenABI from "../../../../store/bscscan/BEP20TokenABI";
import {IWeb3TokenFacade} from "../IWeb3TokenFacade";
import {ERC20Facade} from "../ETH_Network/ERC20Facade";
import {BEP20_DEFAULT_IMG} from "../../../../store/bscscan/BEP20Tokens";


interface IDataForGenerateBEP20Transactions extends IDataForGenerateTransactions {
  balanceDataByAddress: IBalanceTokenData,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

export interface ITxErc20Data extends ITxEthData {
  data: HexStr
}

interface IDataForSendBEP20Transactions extends IDataForSendTransactions {
  balanceDataByAddress: IBalanceTokenData,
  privateKeyByAddress: IMapValueByAddress<IAccount['privateKey']>,
  transactionDataByAddress: IMapValueByAddress<ITxErc20Data>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

class BEP20Facade extends ERC20Facade implements IWeb3TokenFacade {
  constructor() {
    super({
      baseInitData: BscInitData,
      tokenInitData: {
        defaultTokenImage: BEP20_DEFAULT_IMG,
        abi: BEP20TokenABI,
        fetchTokenConf: {
          apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_BSC_SCAN_API,
          url: process.env.REACT_APP_LINK_FOR_BSC_SCAN_API
        }
      }
    });
  }
}

export {BEP20Facade};
import {Loader} from "../ui";

interface LoadingCardsProps {
  cards: {
    name: string
    icon: string
  }[]
}

export const LoadingCards = ({cards}: LoadingCardsProps) => {
  return (
    <>
      {
        cards.map(card => {
          return (
            <div key={card.name} className={`gas-tracker-card ${card.name}`}>
              <h3 className="h3-title font-medium capitalize">{card.name} {card.icon}</h3>

              <Loader text="Loading information ..."/>
            </div>
          )
        })
      }
    </>
  )
}
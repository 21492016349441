import {components, OptionProps, SingleValueProps,} from 'react-select'
import {ITokenDict, ITokenInfo} from "../pages/ConsolidationTool/types";

export type SelectOptionType = {
  label: string,
  value: string
}
export const mapOptionsByTokenDict = (tokensDict: ITokenDict) => {
  const result: SelectOptionType[] = []
  for (const tokenSymbol in tokensDict) {
    result.push({
      label: `${tokensDict[tokenSymbol].title} (${tokensDict[tokenSymbol].symbol})`,
      value: tokensDict[tokenSymbol].symbol
    })
  }
  return result
}

export const createOptionToken = (data: ITokenInfo) => ({
  label: `${data.title} (${data.symbol})`,
  value: data.symbol,
});

export function customSelectValueTokenInit(tokenDict: ITokenDict) {
  return (
    props: SingleValueProps<{
      label: string
      value: ITokenInfo['symbol']
    }>
  ) => {
    const {data} = props
    const tokenData = tokenDict[data.value] || null

    //TODO add default img if it`s undefined

    return (
      <components.SingleValue {...props}>
        {
          tokenData ? (
            <div className="flex items-center gap-2.5">
              <img
                className="h-max"
                width={24}
                height={24}
                src={tokenData?.img}
                alt={tokenData.title}
              />
              {data?.label}
            </div>
          ) : data?.label
        }
      </components.SingleValue>
    )
  }
}

export function customOptionsSelectTokenInit(tokenDict: ITokenDict) {
  return (
    props: OptionProps<{
      label: string
      value: ITokenInfo['symbol']
    }>
  ) => {
    const data = props.data
    const tokenData = tokenDict[data.value] || null
    const cutAddress = tokenData?.address ? tokenData.address.slice(0, 12) + '...' + tokenData.address.slice(-12) : ''

    //TODO add default img if it`s undefined

    return (
      <components.Option {...props}>
        {
          tokenData ? (
            <div className="flex gap-5">
              <img
                className="h-max"
                width={24}
                height={24}
                src={tokenData?.img}
                alt={tokenData.title}
              />

              <div className="grid gap-1">
                <span>{data.label} <span className="bg-coolGray-600 p-0.5 rounded-md">{tokenData?.rate}</span></span>
                {cutAddress ? <span>{cutAddress}</span> : ''}
                <span>{tokenData?.website}</span>
              </div>
            </div>
          ) : data.label
        }
      </components.Option>
    )
  }
}
import React from "react";
import {useParams} from "react-router-dom";
import {withFacade} from "./hoc/withFacade";
import {DisperseToolView} from "./Views/DisperseToolView";
import {IMapValueByAddress, NetworkType} from "../ConsolidationTool/types";
import {BASE_DisperseFacade} from "./facades/BASE_DisperseFacade";
import {ETH_DisperseFacade} from "./facades/ETH_DisperseFacade";
import {BSC_DisperseFacade} from "./facades/BSC_DisperseFacade";


function getFacadeByNetwork(network?: string): any {
  switch (network) {
    case 'eth': {
      return new ETH_DisperseFacade();
    }
    case 'bsc': {
      return new BSC_DisperseFacade()
    }
    case 'base': {
      return new BASE_DisperseFacade()
    }
    default:
      throw new Error(`Network ${network} is not implemented.`);
  }
}

const FactoryDisperseTool = (props: {
  recipientsAndValuesForChargeInUnit?: IMapValueByAddress<bigint>,
  network?: NetworkType
}) => {
  const {network} = useParams<{ network: string }>()
  const initData = props?.recipientsAndValuesForChargeInUnit ? {recipientsAndValuesForChargeInUnit: props.recipientsAndValuesForChargeInUnit} : undefined

  const facade = getFacadeByNetwork(network || 'eth')
  const ReadyComponent = withFacade(DisperseToolView, facade, initData)

  return (<ReadyComponent/>);
};

export {FactoryDisperseTool}
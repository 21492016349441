import {PropsWithChildren, useState} from "react";

interface DropdownProps {
  disabled?: boolean
  menu: {
    key: string
    label: React.ReactNode
    disabled?: boolean
  }[]
}

export const Dropdown = ({
                           children,
                           menu,
                           disabled = false
                         }: PropsWithChildren<DropdownProps>) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  return (
    <div
      className="dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="dropdown-trigger">
        {children}
      </div>

      <div className={`dropdown-menu__wrapper ${isDropdownVisible ? 'show' : ''}`}>
        <div className="dropdown-menu">
          {
            menu.map(item => {
              return (
                <div key={item.key} className="dropdown-menu__item">
                  {item.label}
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import ethereumLogo from '../../assets/images/ethereum-original-light.svg'

const ERC20_DEFAULT_IMG = ethereumLogo as string

const ERC20Tokens: ITokenDict = {
  [NetworkCurrencyEnum.eth]: {
    "title": "Ether",
    "img": "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    "rate": "$3580.00",
    "address": undefined,
    "link": "https://etherscan.io/",
    "group": `Base Currency (${NetworkCurrencyEnum.eth})`,
    "website": "https://ethereum.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.eth
  },
  USDT: {
    "title": "Tether USD",
    "img": "https://etherscan.io/token/images/tethernew_32.png",
    "rate": "$1.00",
    "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
    "link": "https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7",
    "group": "Tokens (ERC 20)",
    "website": "https://tether.to/",
    "desc": "",
    "decimal": 6,
    "symbol": "USDT"
  },
  SHIB: {
    "title": "SHIBA INU",
    "img": "https://etherscan.io/token/images/shibatoken_32.png",
    "rate": "$1.00",
    "address": "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
    "link": "https://etherscan.io/token/0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
    "group": "Tokens (ERC 20)",
    "website": "https://shibatoken.com/",
    "desc": "",
    "decimal": 18,
    "symbol": "SHIB"
  },
  LINK: {
    "title": "ChainLink Token",
    "img": "https://etherscan.io/token/images/chainlinktoken_32.png?v=6",
    "rate": "$18.65",
    "address": "0x514910771af9ca656af840dff83e8264ecf986ca",
    "link": "https://etherscan.io/token/0x514910771af9ca656af840dff83e8264ecf986ca",
    "group": "Tokens (ERC 20)",
    "website": "https://chain.link/",
    "desc": "",
    "decimal": 18,
    "symbol": "LINK"
  },
  UNI: {
    "title": "Uniswap",
    "img": "https://etherscan.io/token/images/uniswap_32.png",
    "rate": "$6.16",
    "address": "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    "link": "https://etherscan.io/token/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    "group": "Tokens (ERC 20)",
    "website": "https://uniswap.org/",
    "desc": "",
    "decimal": 18,
    "symbol": "UNI"
  },
  MANA: {
    "title": "Decentraland",
    "img": "https://etherscan.io/token/images/decentraland_32.png?v=1",
    "rate": "$0.43",
    "address": "0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
    "link": "https://etherscan.io/token/0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
    "group": "Tokens (ERC 20)",
    "website": "https://decentraland.org/",
    "desc": "",
    "decimal": 18,
    "symbol": "MANA"
  },
  AXS: {
    "title": "Axie Infinity Shard",
    "img": "https://etherscan.io/token/images/axieinfinityshard_32.png",
    "rate": "$7.38",
    "address": "0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
    "link": "https://etherscan.io/token/0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
    "group": "Tokens (ERC 20)",
    "website": "https://axieinfinity.com/",
    "desc": "",
    "decimal": 18,
    "symbol": "AXS"
  },
  APE: {
    "title": "ApeCoin",
    "img": "https://etherscan.io/token/images/apecoin_32.png",
    "rate": "$1.37",
    "address": "0x4d224452801aced8b2f0aebe155379bb5d594381",
    "link": "https://etherscan.io/token/0x4d224452801aced8b2f0aebe155379bb5d594381",
    "group": "Tokens (ERC 20)",
    "website": "https://apecoin.com/",
    "desc": "",
    "decimal": 18,
    "symbol": "APE"
  },
  AAVE: {
    "title": "Aave Token",
    "img": "https://etherscan.io/token/images/aave_32.png",
    "rate": "$83.24",
    "address": "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
    "link": "https://etherscan.io/token/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
    "group": "Tokens (ERC 20)",
    "website": "https://aave.com/",
    "desc": "",
    "decimal": 18,
    "symbol": "AAVE"
  },
  COMP: {
    "title": "Compound",
    "img": "https://etherscan.io/token/images/comp_32.png",
    "rate": "$52.53",
    "address": "0xc00e94cb662c3520282e6f5717214004a7f26888",
    "link": "https://etherscan.io/https://etherscan.io/token/0xc00e94cb662c3520282e6f5717214004a7f26888",
    "group": "Tokens (ERC 20)",
    "website": "https://compound.finance/",
    "desc": "",
    "decimal": 18,
    "symbol": "COMP"
  },
  GALA: {
    "title": "Gala",
    "img": "https://etherscan.io/token/images/gala_32.png?v=3",
    "rate": "$52.53",
    "address": "0x15D4c048F83bd7e37d49eA4C83a07267Ec4203dA",
    "link": "https://etherscan.io/https://etherscan.io/token/0x15D4c048F83bd7e37d49eA4C83a07267Ec4203dA",
    "group": "Tokens (ERC 20)",
    "website": "https://games.gala.com/",
    "desc": "",
    "decimal": 8,
    "symbol": "GALA"
  },
  YFI: {
    "title": "yearn.finance",
    "img": "https://etherscan.io/token/images/yfi_32_2.png",
    "rate": "$7,060.98",
    "address": "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
    "link": "https://etherscan.io/https://etherscan.io/token/0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
    "group": "Tokens (ERC 20)",
    "website": "https://yearn.fi/",
    "desc": "",
    "decimal": 18,
    "symbol": "YFI"
  },
  WBTC: {
    "title": "Wrapped BTC",
    "img": "https://etherscan.io/token/images/wbtc_28.png?v=1",
    "rate": "$42,735.00",
    "address": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    "link": "https://etherscan.io/https://etherscan.io/token/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    "group": "Tokens (ERC 20)",
    "website": "https://www.wbtc.network/",
    "desc": "",
    "decimal": 8,
    "symbol": "WBTC"
  },
  FTT: {
    "title": "FTT",
    "img": "https://etherscan.io/token/images/ftexchange_28.png",
    "rate": "$1.60",
    "address": "0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9",
    "link": "https://etherscan.io/https://etherscan.io/token/0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9",
    "group": "Tokens (ERC 20)",
    "website": "https://ftx.com/",
    "desc": "",
    "decimal": 18,
    "symbol": "FTT"
  },
  USDC: {
    "title": "USDC",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    "link": "https://etherscan.io/https://etherscan.io/token/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    "group": "Tokens (ERC 20)",
    "website": "https://www.circle.com/en/usdc",
    "desc": "",
    "decimal": 6,
    "symbol": "USDC"
  },
  CRV: {
    "title": "Curve DAO Token",
    "img": "https://etherscan.io/token/images/Curvefi_32.png",
    "rate": "$0.47",
    "address": "0xD533a949740bb3306d119CC777fa900bA034cd52",
    "link": "https://etherscan.io/https://etherscan.io/token/0xD533a949740bb3306d119CC777fa900bA034cd52",
    "group": "Tokens (ERC 20)",
    "website": "https://www.curve.fi/",
    "desc": "",
    "decimal": 18,
    "symbol": "CRV"
  },
  DAI: {
    "title": "Dai Stablecoin",
    "img": "https://etherscan.io/token/images/MCDDai_32.png",
    "rate": "$1.00",
    "address": "0x6b175474e89094c44da98b954eedeac495271d0f",
    "link": "https://etherscan.io/https://etherscan.io/token/0x6b175474e89094c44da98b954eedeac495271d0f",
    "group": "Tokens (ERC 20)",
    "website": "https://makerdao.com/",
    "desc": "",
    "decimal": 18,
    "symbol": "DAI"
  },
  DYDX: {
    "title": "dYdX",
    "img": "https://etherscan.io/token/images/dydx2_32.png",
    "rate": "$2.78",
    "address": "0x92d6c1e31e14520e676a687f0a93788b716beff5",
    "link": "https://etherscan.io/https://etherscan.io/token/0x92d6c1e31e14520e676a687f0a93788b716beff5",
    "group": "Tokens (ERC 20)",
    "website": "https://dydx.exchange/",
    "desc": "",
    "decimal": 18,
    "symbol": "DYDX"
  },
  IMX: {
    "title": "Immutable X",
    "img": "https://etherscan.io/token/images/immutableimx_new_32.png",
    "rate": "$2.21",
    "address": "0xf57e7e7c23978c3caec3c3548e3d615c346e79ff",
    "link": "https://etherscan.io/https://etherscan.io/token/0xf57e7e7c23978c3caec3c3548e3d615c346e79ff",
    "group": "Tokens (ERC 20)",
    "website": "https://www.immutable.com/",
    "desc": "",
    "decimal": 18,
    "symbol": "IMX"
  },
  BONE: {
    "title": "BONE SHIBASWAP",
    "img": "https://etherscan.io/token/images/shibaswap-bone_32.png",
    "rate": "$0.55",
    "address": "0x9813037ee2218799597d83d4a5b6f3b6778218d9",
    "link": "https://etherscan.io/https://etherscan.io/token/0x9813037ee2218799597d83d4a5b6f3b6778218d9",
    "group": "Tokens (ERC 20)",
    "website": "https://shibaswap.com/",
    "desc": "",
    "decimal": 18,
    "symbol": "BONE"
  }
}

export {ERC20Tokens, ERC20_DEFAULT_IMG}
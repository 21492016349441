import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {ETH_DisperseFacade} from "./ETH_DisperseFacade";


class BSC_DisperseFacade extends ETH_DisperseFacade implements IWeb3DisperseFacade {
  constructor() {
    super({
      web3HttpProviderLink: process.env.REACT_APP_BSC_WEB3_HTTP_PROVIDER,
      network: 'bsc',
      linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_BSC_SCAN,
      defaultTransactionPriority: "high",
      fetchGasPriceConf: {
        apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_BSC_SCAN_API,
        url: process.env.REACT_APP_LINK_FOR_BSC_GAS_PRICE_API
      }
    })
  }
}

export {BSC_DisperseFacade}
import {createApi, FetchArgs} from "@reduxjs/toolkit/query/react";
import {BaseQueryFn, fetchBaseQuery, FetchBaseQueryError} from "@reduxjs/toolkit/query/react";
import {GasFeeEstimationResponse} from "./types";

export const baseQuery: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError
  > = async (args, api, extraOptions) => {
  args.params = {...args.params, apikey: process.env.REACT_APP_OWLRACLE_API_KEY}
  const baseUrl = 'https://api.owlracle.info/v4/';

  const rawBaseQuery = fetchBaseQuery({
    baseUrl
  });

  return rawBaseQuery(args, api, extraOptions);
}

export const owlracleApi = createApi({
  reducerPath: 'owlracle/api',
  baseQuery,
  endpoints: builder => ({
    getGasFeeEstimation: builder.query<GasFeeEstimationResponse, 'eth' | 'bsc'>({
      query: (network) => {
        return {
          url: `${network}/gas`,
        }
      },
    })
  })})

export const {useGetGasFeeEstimationQuery} = owlracleApi
import {Loader} from "../ui";
import {toast} from "react-toastify";
import {ITokenInfo} from "../../pages/ConsolidationTool/types";

interface TokenInfoProps {
  dataToken: ITokenInfo | null | undefined
  dataTokenStatus: boolean,
  dataTokenMessage: string | null,
  isLoading: boolean
}

export const TokenInfo = ({
                            dataToken,
                            dataTokenStatus,
                            dataTokenMessage,
                            isLoading,
                          }: TokenInfoProps) => {

  if (isLoading) {
    return (
      <Loader
        className="mt-5"
        text={'Loading currency info...'}
      />
    )
  }

  if (!dataTokenStatus) return <p className="text-center text-red-700 mt-5">{dataTokenMessage}</p>

  if (!dataToken) return null

  const {address, symbol, title, decimal} = dataToken
  return (
    <div className="mt-5 grid grid-cols-2 gap-5 border border-solid border-blueGray-300 rounded-2xl p-5">
      <div>
        <h4 className="h5-title text-blueGray-300">Name:</h4>
        <div className="text-base">{title}</div>
      </div>

      {address ? <div>
        <h4 className="h5-title text-blueGray-300">Contract address:</h4>
        <div
          className="text-base break-all hover:opacity-70 transition-all cursor-pointer"
          onClick={() => {
            navigator.clipboard.writeText(address)
            toast.success('Copied: ' + address)
          }}
        >
          {address} <i className="icon-copy"/>
        </div>
      </div> : <div></div>}

      <div>
        <h4 className="h5-title text-blueGray-300">Symbol:</h4>
        <div className="text-base">{symbol}</div>
      </div>

      <div>
        <h4 className="h5-title text-blueGray-300">Decimal:</h4>
        <div className="text-base">{decimal}</div>
      </div>
    </div>
  );
};

import {PropsWithChildren} from "react";

interface BlockProps extends PropsWithChildren {
  className?: string
}

export const Block = ({children, className = ''}: BlockProps) => {
  return (
    <div className={`rounded-2xl bg-zinc-700 shadow-xl p-5 pb-10 ${className}`}>
      {children}
    </div>
  )
}
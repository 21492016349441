import {Outlet} from "react-router-dom";
import {Header} from "./components";

export const Layout = () => {
  return (
    <div className="wrapper">
      <Header/>

      <main className="main-container">
        <Outlet/>
      </main>
    </div>
  )
}
export enum ButtonSize {
  XS = 'btn-size-xs',
  S = 'btn-size-s',
  M = 'btn-size-m',
  L = 'btn-size-l',
  XL = 'btn-size-xl',
  XXL = 'btn-size-xxl',
  FULL = 'w-full'
}

export type Option = {
  label: string
  value: string | number
}

export const cardsGasFeeEstimation = [
  {
    name: 'slow',
    tooltip: 'Accepted on 35% of blocks.',
    icon: '🛴'
  },
  {
    name: 'standard',
    tooltip: 'Accepted on 60% of blocks.',
    icon: '🚗'
  },
  {
    name: 'fast',
    tooltip: 'Accepted on 90% of blocks.',
    icon: '✈️'
  },
  {
    name: 'instant',
    tooltip: 'Accepted on every block.',
    icon: '🚀'
  },
]

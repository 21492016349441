import {IWeb3Facade} from "../IWeb3Facade";
import {HexStr} from "../../../../store/web3/web3";
import {
  IAccount,
  IBalanceData,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IGeneralTxData,
  IMapValueByAddress,
  ITransactionPriorityEnum,
} from "../../types";
import {BEP20Tokens} from "../../../../store/bscscan/BEP20Tokens";
import {ETHFacade, InitDataType} from "../ETH_Network/ETHFacade";
import {BEP20TestnetTokens} from "../../../../store/bscscan/BEP20TestnetTokens";


interface IDataForGenerateBSCTransactions extends IDataForGenerateTransactions {
  balanceDataByAddress: IBalanceData,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

interface IDataForSendBSCTransactions extends IDataForSendTransactions {
  balanceDataByAddress: IBalanceData,
  privateKeyByAddress: IMapValueByAddress<IAccount['privateKey']>,
  transactionDataByAddress: IMapValueByAddress<ITxEthData>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

export interface ITxEthData extends IGeneralTxData {
  from: HexStr,
  to: HexStr,
  value: HexStr,
  chainId: HexStr,
  networkId: HexStr,
  gas: HexStr,
  gasPrice: number | string,
  nonce: HexStr,
}

const TransactionPriorityEnum: ITransactionPriorityEnum = {
  low: "low",
  medium: "medium",
  high: "high"
} as const

export const BscInitData:InitDataType = {
  defaultTransactionPriority: TransactionPriorityEnum.medium,
  transactionPriorityOptions: {
    [TransactionPriorityEnum.low]: "Standard",
    [TransactionPriorityEnum.medium]: "Fast",
    [TransactionPriorityEnum.high]: "Rapid"
  },
  fetchGasPriceConf: {
    apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_BSC_SCAN_API,
    url: process.env.REACT_APP_LINK_FOR_BSC_GAS_PRICE_API
  },
  web3HttpProviderLink: process.env.REACT_APP_BSC_WEB3_HTTP_PROVIDER,
  tokensDict: process.env.REACT_APP_ENVIRONMENT === 'dev' ? BEP20TestnetTokens : BEP20Tokens,
  network: 'bsc',
  linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_BSC_SCAN
}

class BSCFacade extends ETHFacade implements IWeb3Facade {

  constructor() {
    super(BscInitData)
  }
}

export {BSCFacade}
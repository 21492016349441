import {Router} from "../Router";
import {store} from "../../store";
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";
import packageInfo from "../../../package.json"

export const App = () => {
  /**
   * NO DELETE
   * For understanding which version on servers (dev|prod|stable)
   */
  console.info(`Current Version: ${packageInfo.version}`)

  return (
    <Provider store={store}>
      <Router/>

      <ToastContainer
        position="bottom-left"
        theme="dark"
        hideProgressBar
        autoClose={2000}
      />
    </Provider>
  )
}
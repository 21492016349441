import {
  ComputeBudgetProgram,
  Connection,
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import {decode as decodeBase58, encode as encodeBase58} from "bs58"
import {AddressType, PrivateKeyType} from "../../pages/ConsolidationTool/types";
import {Web3Context} from "web3";
import {Buffer} from "buffer";
import {SendOptions} from "@solana/web3.js/src/connection";


export const NUM_DROPS_PER_TX = 10;
export const TX_INTERVAL = 1000;

const SOL_PER_LAMPORT = 1 / LAMPORTS_PER_SOL
export const MICRO_LAMPORTS_PER_LAMPORT = 1_000_000;
const SOL_FLOATING_PRECISION = 9


export type GetTokenAccountsByOwnerParsedResponse = {
  context: {
    apiVersion: string,
    slot: number
  },
  value: [
    {
      "account": {
        "data": {
          "parsed": {
            "info": {
              "isNative": boolean,
              "mint": AddressType,
              "owner": AddressType,
              "state": string,
              "tokenAmount": {
                "amount": string,
                "decimals": number,
                "uiAmount": number,
                "uiAmountString": string
              }
            },
            "type": string
          },
          "program": string,
          "space": number
        },
        "executable": boolean,
        "lamports": number,
        "owner": string,
        "rentEpoch": number,
        "space": number
      },
      "pubkey": AddressType
    }
  ]
}

export function setProviderWeb3(linkHttpProvider: string) {
  const connection = new Connection(linkHttpProvider, "confirmed")

  /**
   * @param {float} amount
   * @param {int} length
   *
   * @return {float}
   */
  function __truncate_float(amount: number, length: number): number {
    amount = amount * Math.pow(10, length)
    amount = Math.trunc(amount)
    amount /= Math.pow(10, length)
    return amount
  }

  function lamport_to_sol(lamports: number): number {
    return __truncate_float(lamports * SOL_PER_LAMPORT, SOL_FLOATING_PRECISION)
  }

  function sol_to_lamport(sol: number): number {
    return Math.trunc(sol * LAMPORTS_PER_SOL)
  }

  const __getBalanceRequest = (address: AddressType, prefix = '') => ({
    id: `${prefix.length ? prefix + '.' : prefix}${address}`,
    method: "getBalance",
    params: [
      address
    ]
  })
  const __sendRawTransactionRequest = (rawTransaction: Buffer | Uint8Array | Array<number>, address: AddressType, options?: SendOptions) => {

    const encodedTransaction = Buffer.from(rawTransaction).toString('base64')
    const config: any = {encoding: 'base64'};
    const skipPreflight = options && options.skipPreflight;
    const preflightCommitment =
      (options && options.preflightCommitment) || "confirmed";

    if (options && options.maxRetries != null) {
      config.maxRetries = options.maxRetries;
    }
    if (options && options.minContextSlot != null) {
      config.minContextSlot = options.minContextSlot;
    }
    if (skipPreflight) {
      config.skipPreflight = skipPreflight;
    }
    if (preflightCommitment) {
      config.preflightCommitment = preflightCommitment;
    }
    return {
      id: `${address}`,
      method: "sendTransaction",
      params: [
        encodedTransaction,
        config
      ]
    }
  }
  /**
   * Returns all SPL Token accounts by token owner.
   * @inheritDoc https://www.quicknode.com/docs/solana/getTokenAccountsByOwner
   *
   * @param walletPubKey
   * @param tokenAddress
   *
   * @see GetTokenAccountsByOwnerParsedResponse
   */
  const __getTokenAccountsByOwner = (walletPubKey: PublicKey, tokenAddress: PublicKey) => {
    return {
      id: `${walletPubKey.toBase58()}`,
      method: "getTokenAccountsByOwner",
      params: [
        walletPubKey.toBase58(),//The Pubkey of account delegate to query encoded as base-58 string
        {
          "mint": tokenAddress.toBase58() //The Pubkey of the specific token Mint to limit accounts to, as base-58 encoded string
        },
        {
          "encoding": "jsonParsed"
        },
      ]
    }
  }


  /**
   * @experimental Only for Helius RPC !!!
   *  docs https://docs.helius.dev/solana-rpc-nodes/alpha-priority-fee-api
   * @param rawTransaction
   * @param address
   */
  const __getPriorityFeeEstimateRequest = (rawTransaction: Buffer | Uint8Array | Array<number>, address: AddressType) => ({
    id: `${address}`,
    method: "getPriorityFeeEstimate",
    params: [
      {
        transaction: encodeBase58(rawTransaction), // Pass the serialized transaction in Base58
        options: {
          // priorityLevel: priorityLevel,
          includeAllPriorityFeeLevels: true
        },
      },
    ],
  })

  const getBalance = Object.assign(connection.getBalance, {
    request: __getBalanceRequest,
    outputFormatter: BigInt
  })
  const sendRawTransaction = Object.assign(connection.sendRawTransaction.bind(connection), {
    request: __sendRawTransactionRequest,
  })
  const getParsedTokenAccountsByOwner = Object.assign(connection.getParsedTokenAccountsByOwner.bind(connection), {
    request: __getTokenAccountsByOwner,
    outputFormatter: BigInt
  })
  /**
   * @experimental more details https://docs.helius.dev/solana-rpc-nodes/alpha-priority-fee-api
   */
  const getPriorityFeeEstimate = Object.assign(async (rawTransaction: Buffer | Uint8Array | Array<number>) => {
    const response = await fetch(linkHttpProvider, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: "1",
        method: "getPriorityFeeEstimate",
        params: [
          {
            transaction: encodeBase58(rawTransaction), // Pass the serialized transaction in Base58
            options: {
              // priorityLevel: priorityLevel,
              includeAllPriorityFeeLevels: true
            },
          },
        ],
      }),
    });
    return await response.json() as {
      "jsonrpc": "2.0",
      "result"?: {
        "priorityFeeLevels": { // for options {includeAllPriorityFeeLevels: true}
          "min": number,
          "low": number,
          "medium": number,
          "high": number,
          "veryHigh": number,
          "unsafeMax": number
        }
      },
      error?: { code: number, message: string }
      "id": "1"
    };
  }, {
    request: __getPriorityFeeEstimateRequest,
  })

  function BatchRequestInit() {
    return new Web3Context(linkHttpProvider).BatchRequest
  }

  return {
    connection,
    getParsedTokenAccountsByOwner,
    getMinimumBalanceForRentExemption: connection.getMinimumBalanceForRentExemption.bind(connection),
    simulateTransaction: connection.simulateTransaction.bind(connection),
    getLatestBlockhash: connection.getLatestBlockhash.bind(connection),
    PublicKey,
    SystemProgram,
    ComputeBudgetProgram,
    Transaction, Keypair,
    sendRawTransaction,
    getPriorityFeeEstimate,
    getBalance,
    getAccountInfo: connection.getAccountInfo.bind(connection),
    getKeyPair: (privateKey: PrivateKeyType) => {
      return Keypair.fromSecretKey(decodeBase58(privateKey), {skipValidation: false})
    },
    privateKeyToAccount: (privateKey: PrivateKeyType) => {
      const keyPair = Keypair.fromSecretKey(decodeBase58(privateKey), {skipValidation: false})
      return {
        address: keyPair.publicKey.toBase58(),
        /**
         * better to use encodeBase58(keyPair.secretKey)
         * but for optimize perform may use fn param [privateKey]
         */
        privateKey: privateKey,
        // privateKey: encodeBase58(keyPair.secretKey)
      }
    },
    isAddress: (address: AddressType) => {
      const pubKey = new PublicKey(address)
      return PublicKey.isOnCurve(pubKey.toString())
    },
    lamport_to_sol,
    sol_to_lamport,
    BatchTransaction: Transaction,
    BatchRequest: BatchRequestInit()
  }
}

export type Web3SolType = ReturnType<typeof setProviderWeb3>

